<template>
    <div style="width: 90%;margin-left: 5%;">
      <vue-audio-player
        ref="audioPlayer"
        :audio-list="audioList"
        :before-play="handleBeforePlay"
        :show-play-loading="false"
        @ended="handleEnded"
      >
      </vue-audio-player>
  
    </div>
  </template>
  <script>
  import VueAudioPlayer from '@liripeng/vue-audio-player'
  
  export default {
    components: {
      VueAudioPlayer,
    },
  
    data() {
      return {
        currentAudioName: '',
        audioList: [
          {
            src: localStorage.getItem("play2"), // Required
            title: 'Audio Title 1', // Optional，Phone lock screen music player display
            artist: 'Artist Name 1', // Optional，Phone lock screen music player display
            album: 'Album Name 1', // Optional，Phone lock screen music player display
            artwork: [
              {
                src: 'https://upload.jianshu.io/users/upload_avatars/1696356/c358e43854eb?imageMogr2/auto-orient/strip|imageView2/1/w/96/h/96/format/webp',
                sizes: '512x512',
                type: 'image/jpg',
              },
            ], // Optional，Phone lock screen music player display
          },
          
        ],
      }
    },
  
    methods: {
      // Use this function if you want to do something before you start playing
      handleBeforePlay(next) {
        this.currentAudioName =''
        this.audioList[this.$refs.audioPlayer.currentPlayIndex].title
        next() // Start play
      },
  
      handlePlaySpecify() {
        this.$refs.audioPlayer.currentPlayIndex = 1
        this.$nextTick(() => {
          this.$refs.audioPlayer.play()
          this.title =''
            this.audioList[this.$refs.audioPlayer.currentPlayIndex].name
        })
      },
  
      handleEnded() {
        console.log('ended')
      },
      
    },
    beforeRouteLeave(to,from,next){
        alert(1)
        next(to)
    },
    created(){
        localStorage.setItem("playerRefesh",1)
        let actid = this.$route.query['id']
    if(actid){
        localStorage.setItem('vid',actid)
        let d ={}
        d[actid]=0
        localStorage.setItem('audioCurrentTime',JSON.stringify(d))
    }
    },
    mounted(){
        
    }
  }
  </script>
  
  <style scoped>
  .name {
    text-align: center;
    line-height: 80px;
  }
  </style>
  