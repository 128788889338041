import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Element3 from 'element3'
//  import '../node_modules/element3/lib/theme-chalk/index.css';
// import ElementUI from 'element-ui';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import axios from 'axios'
import { register } from 'register-service-worker'
import './registerServiceWorker'

axios.defaults.withCredentials=true;//让ajax携带cookie
window.g={
    'base':process.env.NODE_ENV==="development"?"https://api.mingdao.com":"https://api.mingdao.com",
    "permission":"https://www.mingdao.com/",
    'ck':Math.round(Date.now()/1000),
    'fwd':process.env.NODE_ENV==="development"?'http://192.168.3.2:8000/':'https://cqzy.api.zhongblock.com:442',
    'store':'https://shuaizhe.oss-cn-shenzhen.aliyuncs.com/',
    // 'fwd':'http://192.168.72.47:8000/'
    // fwd:'https://cqzy.api.zhongblock.com:442'
}
window.app = {
    router // You can add other global variables here if needed
  };
router.beforeEach(
    (to,from,next)=>{
        // alert(this.$route.meta.contain)
        let code = to.meta.contain  // 下标记录
        localStorage.setItem("homecode",code?code:'行情')
        // 变化颜色
        // let code = {'/my':'我的','/search':'资讯','/':"行情",'/data':'数据'}
        // alert(localStorage.getItem("homecode"))
        let fpath = to['fullPath']
        let query = to['query']
        if(fpath.includes("/vipComment/detail/?id=")&&fpath.includes("secret")){
            axios.post(window.g.fwd+'/getdata/yz/',query).then(
                res=>{
                    let tf =res['data']['succeed']
                    if(tf){
                        next()
                    }
                }
            )
        }else{
            let ck = localStorage.getItem('ck')
            let user = localStorage.getItem("user")
            if(ck){ // 如果存在验证 直接返回
                if(user==='普通用户'){
                    if(to['name']==='cz' || to['name']==='my' || to['name']==='out' ||to['name']==='xf'){
                        next()
                    }else{
                        next({path:'/my/cz/'})
                    }
    
                }else{
                    next()
                }
            }else{
                if(to['name']==='ulogin' || to['name']==='register'){
                    next()
                }
                next({
                    path:'/user/login'
                })
            }
        }
        
        
    }
)
import VueAudioPlayer from '@liripeng/vue-audio-player'
const app = createApp(App)
app
    .use(store)
    .use(router)
    .use(ElementPlus)
    .use(ElementPlusIconsVue)
    .use(VueAudioPlayer)
.mount('#app')
// 全局注册el-icon
for (const [name, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(name, component);
}
