<template> 
  <div id="bofang" >
    <span style="font-size:1px;opacity: 0;">{{ this.$route.name }}     {{ ltf }}    </span>
    <div v-if="def" style="background: white;">
        <div class="colse" @click="colse" style="opacity: 0.5;"><el-icon><CloseBold /></el-icon></div>
        <div class="left shouqi"  @click="hide" >
          <el-icon><CaretLeft /></el-icon>
        </div>
        <div class="left yp" v-show="isshow" style="background: white;">
            <play ></play>
        </div>
    </div>
  </div>
</template>

<script>
    // import play from './apple-aPlayer.vue'
    import play from './new-apple-aPlayer.vue'
    export default {
        
    components:{
        play
  },
  data(){
    return {
        isshow:true,
        isAllShow:true,
        ptf:true,
        ltf:localStorage.getItem('vid'),
        t:'<',
        def:false
    }
  },
  methods:{
    hide(){
        this.isshow=!this.isshow
    },
    colse(){
      localStorage.removeItem('vid')
      document.getElementById('bofang').style.display='none'
      this.hide()

    }
    },
    updated(){
        this.ptf=this.$route.name!=='vipComment-detail'&&this.$route.name!=='login'&&this.$route.name!=='register'
        this.def=this.ptf&&this.ltf
        if(this.ptf&&this.ltf){
            // 播放
        }

    },
    created(){
    },
    mounted(){
      const refesh = localStorage.getItem("playerRefesh")?0:1
        if(refesh===1){ 
            location.reload();
            localStorage.setItem("playerRefesh",0)
            location.reload();
    }
    }
}
</script>

<style scoped>
  #bofang{
    position:absolute;
    bottom:15%;
    right:0%;
    z-index: 3;
  }
  .left{
    float:left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shouqi{
    width:20px;
    height: 150px;
    background: #a0cfff;
    opacity: 0.5;
  }
  .yp{
    height:150px;
    width:90%
  }
  .colse{
    height: 20px;
    width:20px;
    /* display: flex;
    justify-content: center; */
  }
</style>