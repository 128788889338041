import { createRouter, createWebHistory,createWebHashHistory  } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path:"/login/:uname",
    name:'login',
    component: () => import('../market/login.vue'),
    
  },
  {
    path:"/user/login",
    name:'ulogin',
    component: () => import('../login/login.vue')
  },
  {
    path:"/user/register",
    name:'register',
    component: () => import('../login/register.vue')
  },
  {
    path:"/my/cz",
    name:'cz',
    component: () => import('../my/cz.vue'),
    meta:{
      contain:'我的'
    }
  },
  { meta:{
    contain:'资讯',
     home:true
  },
    path:"/search",
    name:'search',
    component: () => import('../tool/tool.vue')
  },
    { meta:{
      contain:'资讯'
    },
      path:"/search/perDaytotal",
      name:'perDaytotal',
      component: () => import('../tool/model/perDaytotal.vue')
    },
    {
      path: '/',
      name: 'main',
      component: () => import('../market/main.vue'),
      meta:{
        keepAlive:true,
        contain:"行情",
        home:true
      }
    },
    {
      path:"/detail",
      name:"market-detail",
      component:()=>import("../market/detail.vue"),
      meta:{
        contain:'行情'
      }
    },
    {
      path:'/chain',
      name:'chain',
      component:()=>import("../market/chains.vue"),
      meta:{
        contain:'行情'
      }
    },
    // {
    //   path:"/moudle",
    //   name:"moudle",
    //   component:()=>import("../market/moudle.vue")

    // },

    // 资讯一块

    {
      path:'/newfather',
      name:"nav",
      component: () => import('../news/navigation.vue'),
      
      meta:{
        contain:'资讯'
      },
      children:[
        {
          path: 'news',
          name: 'news',
          meta:{
            contain:'资讯'
          },
          component: () => import('../news/main.vue')
        },
        {  // 项目文章  （导航 项目文章及评论）
          path: 'project',
          name: 'news-project',
          meta:{
            contain:'资讯'
          },
          component: () => import('../news/project.vue')
        },
        {  // 新闻  （导航  项目新闻）
          path: 'project_news',
          name: 'news-project-news',
          meta:{
            contain:'资讯'
          },
          component: () => import('../news/projectNews.vue')
        },
        {  // 新闻详细页面
          path: 'project_news_detail',
          name: 'news-project-news-detail',
          meta:{
            contain:'资讯'
          },
          component: () => import('../news/projectNewsDetai.vue')
        },
        {  //交易所动态（数据 交易所动态)
          path: 'trade',
          name: 'news-trade',
          meta:{
            contain:'资讯'
          },
          component: () => import('../news/trade.vue')
        },
        {  // 推特 （app功能页  名人twitter和项目或者名人）
          path: 'tk',
          name: 'news-tk',
          meta:{
            contain:'资讯'
          },
          component: () => import('../news/tk.vue')
        },
        // {
        //   path:"project_detail",
        //   name: 'project_detail',
        //   component: () => import('../news/tk/project-detail.vue')
        // },
        
        {  // 日历   （数据  日历）
          path: 'calendar',
          name: 'news-calendar',
          meta:{
            contain:'资讯'
          },
          component: () => import('../news/calendar.vue')
        },
        { // vip新闻解读
          path: 'vip',
          name: 'news-vip',
          meta:{
            contain:'资讯'
          },
          component: () => import('../news/vip.vue')
        },
    
      ]
    },
    {
      path:"/my/project_detail",
      name: 'project-detail',
      meta:{
        contain:'我的'
      },
      component: () => import('../my/model/project-detail.vue')
    },
    ,


    {
      path: '/db',
      name: 'db',
      meta:{
        contain:'数据'
      },
      component: () => import('../data/db.vue')
    },
    {
      path: '/data',
      name: 'data',
      
      meta:{
        contain:'数据',
        home:true
      },
      component: () => import('../data/main.vue'),
      children:[
        {
          path:'crypot',
          name:'crypot',
          meta:{
            contain:'数据'
          },
          component: () => import('../data/model/crypot.vue'),
        },
        {
          path:'shanzhai',
          name:'shanzhai',
          meta:{
            contain:'数据'
          },
          component: () => import('../data/model/shanzhai.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:'coinglass',
          name:'coinglass',
          component: () => import('../data/model/coinglass.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:'ordiscan',
          name:'ordiscan',
          component: () => import('../data/model/ordiscan.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:'hot',
          name:'hot',
          component: () => import('../data/model/hot.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:'token',
          name:'token',
          component: () => import('../data/model/token.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:'els',
          name:'els',
          component: () => import('../data/model/els.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:'overview',
          name:'overview',
          component: () => import('../data/model/overview.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:'bchain',
          name:'bchain',
          component: () => import('../data/model/bchain.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:'unlock',
          name:'unlock',
          component: () => import('../data/model/unlock.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:"class",
          name:'class',
          component: () => import('../data/model/class-graph.vue'),

        },
        {
          meta:{
            contain:'数据'
          },
          path:"tradetotal",
          name:'tradetotal',
          component: () => import('../data/trade/trade-total.vue'),
        },
        {
          meta:{
            contain:'数据'
          },
          path:"chengjiaoChicang",
          name:'chengjiaoChicang',
          component: () => import('../data/trade/totalChengjiaoChicang.vue'),
        }
      ]
    },
    {
      meta:{
        contain:'我的',
        home:true
      },
      path: '/my',
      name: 'my',
      component: () => import('../my/main.vue'),
      
    },
    {meta:{
      contain:'我的'
    },
      path: '/my/xf',
      name: 'xf',
      component: () => import('../my/xf.vue')
    },
    {meta:{
      contain:'我的'
    },
      path: '/my/myconcern',
      name: 'myconcern',
      component: () => import('../my/myconcern.vue')
    },
    { meta:{
      contain:'我的'
    },
      path: '/my/myCk',
      name: 'myCk',
      component: () => import('../my/myCk.vue')
    },
    { meta:{
        contain:'我的'
      },
      path: '/my/myCk/add',
      name: 'myCkadd',
      component: () => import('../my/ck/add.vue')
    },
    { meta:{
      contain:'我的'
    },
      path: '/my/myCk/detail',
      name: 'myCkdetail',
      component: () => import('../my/ck/detail.vue')
    },
    {meta:{
      contain:'我的'
    },
      path: '/my/myInfo',
      name: 'myInfo',
      component: () => import('../my/myInfo.vue')
    },
    {meta:{
      contain:'我的'
    },
      path: '/my/out',
      name: 'out',
      component: () => import('../my/out.vue')
    },
    {
      meta:{
      contain:'资讯'
    },
      path:"/search/project_detail",
      name:"content-detail",
      component: () => import('../tool/model/content-detail.vue')

    },
    // 评论 社交
    {meta:{
      contain:'我的'
    },
      path:"/comment/home/",
      name:'comment-home',
      component: () => import('../comment/home.vue')
    },
    {meta:{
      contain:'我的'
    },
      path:"/comment/write/",
      name:'comment-write',
      component: () => import('../comment/write.vue')
    },
    {meta:{
      contain:'我的'
    },
      path:"/comment/rank/",
      name:'comment-rank',
      component: () => import('../comment/rank.vue')
    },
    {meta:{
      contain:'我的'
    },
      path:"/comment/detail/",
      name:'comment-detail',
      component: () => import('../comment/detail.vue')
    },

    {
      meta:{
        contain:'资讯'
      },
      path:"/vipComment/detail/",
      name:'vipComment-detail',
      component: () => import('../comment/vipComment.vue')
    },

    {
      meta:{
        contain:'行情'
      },
      path:"/total/home/",
      name:'market-home',
      component: () => import('../marketTotal/home.vue')
    },
    
    {
      meta:{
        contain:'行情'
      },
      path:"/tradeMain/",
      name:'tradeMain',
      component: () => import('../trading/main.vue')
    }
]

const router = createRouter({
  // history: createWebHashHistory (),
  history:createWebHistory(),
  mode: 'history',

  routes
})

export default router
